import { createEntityAdapter } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { LoaderFunctionArgs } from 'react-router';
import { store } from '../../Core/AppStore';
import { ApiEndpoints } from '../../Core/http/api-endpoints';
import { api, TagType } from '../../Core/http/api.ts';
import { http } from '../../Core/http/http.ts';
import { Invoice, InvoicePayment, InvoiceSettings } from './invoices.types';

const { InvoicesUrl, PrintInvoiceUrl, InvoicePaymentsUrl } = ApiEndpoints;

export interface InvoiceFilters {
  client?: string; // client id
  q?: string;
}

export const saveToPDF = async (invoice: Invoice, config?: AxiosRequestConfig) => {
  const { data } = await http.post<Invoice, { data: BlobPart }>(`${PrintInvoiceUrl}/${invoice?.id}`, invoice, {
    ...config,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'blob',
  });

  return { data };
};

const invoicesAdapter = createEntityAdapter<Invoice>();
const initialState = invoicesAdapter.getInitialState();

export const invoiceApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllInvoices: build.query<Record<string, Invoice>, string | void>({
      query: () => ({ url: `${InvoicesUrl}`, method: 'GET' }),
      providesTags: (result) => {
        return result
          ? [TagType.Invoices, { type: TagType.Invoices, id: `${TagType.Invoices}-LIST` }]
          : [{ type: TagType.Invoices, id: `${TagType.Invoices}-LIST` }];
      },
      transformResponse: (response: Invoice[]) => {
        const { entities } = invoicesAdapter.setAll(initialState, response);
        return entities;
      },
    }),
    filterInvoices: build.query<Record<string, Invoice>, string>({
      query: (q) => ({ url: `${InvoicesUrl}/${q ? '?q=' + encodeURI(q) : ''}`, method: 'GET' }),
      providesTags: (result) => {
        return result ? [TagType.Invoices] : [{ type: TagType.Invoices, id: `${TagType.Invoices}-LIST` }];
      },
      transformResponse: (response: Invoice[]) => {
        const { entities } = invoicesAdapter.setAll(initialState, response);
        return entities;
      },
    }),
    getInvoice: build.query<Invoice, string>({
      query: (invoiceId: string) => ({ method: 'GET', url: `/${InvoicesUrl}/${invoiceId}` }),
      providesTags: (_result, _error, id) => [{ type: TagType.Invoices, id }],
      extraOptions: { maxRetries: 2 },
    }),
    createInvoice: build.mutation<Invoice, Invoice>({
      query: (invoice: Invoice) => ({ method: 'POST', url: InvoicesUrl, data: invoice }),
      invalidatesTags: () => [
        { type: TagType.Invoices },
        { type: TagType.DashboardCounters },
        { type: TagType.DashboardInvoicesChart },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    updateInvoice: build.mutation<Invoice, Invoice>({
      query: (invoice: Invoice) => ({ method: 'PUT', url: `/${InvoicesUrl}/${invoice.id}`, data: invoice }),
      invalidatesTags: (_result, _error, invoice) => [
        { type: TagType.Invoices, id: invoice.id },
        { type: TagType.Invoices },
        { type: TagType.Invoices, id: `${TagType.Invoices}-LIST` },
        { type: TagType.DashboardInvoicesChart },
      ],
    }),
    deleteInvoice: build.mutation<Invoice, string>({
      query: (invoiceId: string) => ({ method: 'DELETE', url: `/${InvoicesUrl}/${invoiceId}` }),
      invalidatesTags: () => [
        { type: TagType.DashboardCounters },
        { type: TagType.Trash },
        { type: TagType.Invoices, id: `${TagType.Invoices}-LIST` },
        { type: TagType.DashboardInvoicesChart },
      ],
    }),
    replicateInvoice: build.mutation<Invoice, Invoice>({
      query: (invoice) => ({ method: 'POST', url: `/${InvoicesUrl}/replicate`, data: invoice }),
      invalidatesTags: (_result, _error, invoice) => [
        { type: TagType.DashboardCounters },
        { type: TagType.Invoices },
        { type: TagType.Invoices, id: invoice.id },
        { type: TagType.Invoices, id: `${TagType.Invoices}-LIST` },
      ],
    }),
    addInvoicePayment: build.mutation<Invoice, { invoice: Invoice; payment: InvoicePayment }>({
      query: ({ invoice, payment }) => ({
        method: 'POST',
        url: `/${InvoicePaymentsUrl}/${invoice.id}`,
        data: payment,
      }),
      invalidatesTags: (_result, _error, { invoice }) => [
        { type: TagType.Invoices },
        { type: TagType.Invoices, id: `${TagType.Invoices}-LIST` },
        { type: TagType.Invoices, id: invoice.id },
        { type: TagType.DashboardInvoicesChart },
      ],
    }),
    updateInvoiceSettings: build.mutation<Invoice, { invoice: Invoice; settings: InvoiceSettings }>({
      query: ({ invoice, settings }) => ({
        method: 'POST',
        url: `/${InvoicesUrl}/${invoice.id}/settings`,
        data: settings,
      }),
      invalidatesTags: (_result, _error, { invoice }) => [{ type: TagType.Invoices, id: invoice.id }],
    }),
  }),
});

export const invoicesLoader = async ({ request }: LoaderFunctionArgs) => {
  const promise = store.dispatch(invoiceApi.endpoints.getAllInvoices.initiate());
  request.signal.onabort = () => promise.abort();
  // await promise;
  promise.unsubscribe();
  return promise;
};

export const invoiceLoader = async ({ params, request }: LoaderFunctionArgs) => {
  const promise = store.dispatch(invoiceApi.endpoints.getInvoice.initiate(params.id! as string));
  request.signal.onabort = () => promise.abort();
  await promise;
  promise.unsubscribe();
  return promise;
};

export const initiateInvoiceRequest = (id: string) => {
  const promise = store.dispatch(invoiceApi.endpoints.getInvoice.initiate(id));
  promise.unsubscribe();
  return promise;
};

export const initiateAllInvoicesRequest = () => {
  const promise = store.dispatch(invoiceApi.endpoints.getAllInvoices.initiate());
  promise.unsubscribe();
  return promise;
};

export const {
  useGetAllInvoicesQuery,
  useFilterInvoicesQuery,
  useGetInvoiceQuery,
  useCreateInvoiceMutation,
  useUpdateInvoiceMutation,
  useDeleteInvoiceMutation,
  useAddInvoicePaymentMutation,
  useReplicateInvoiceMutation,
  useUpdateInvoiceSettingsMutation,
} = invoiceApi;
