import * as Sentry from '@sentry/react';
import * as React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { authUserLoader } from '../Authentication/store/authenticatedUser.api.ts';
import { cashPaymentLoader, cashPaymentsLoader } from '../CashPayments/api/payments.api.ts';
import { clientLoader, clientsLoader } from '../Clients/api/clients.api.ts';
import { AppLogout } from '../Core/components/AutoLogout.tsx';
import { Navbar } from '../Core/components/Navbar/Navbar.tsx';
import { PrivateRoute } from '../Core/components/PrivateRoutes/PrivateRoute.tsx';
import { estimateLoader, estimatesLoader } from '../Estimates/api/estimates.api.ts';
import { expenseLoader, expensesLoader } from '../Expenses/api/expenses.api.ts';
import { invoiceLoader, invoicesLoader } from '../Invoices/api/invoices.api.ts';
import { productLoader, productsLoader } from '../Products/api/products.api.ts';
import { projectLoader, projectsLoader } from '../Projects/api/projects.api.ts';
import { AppContainer, Root, RouteNotFound } from '../Root.tsx';
import { EstimateCreatorDataLoader, InvoiceCreatorDataLoader } from '../Treasuries/components/TreasuryDataLoader.tsx';
import { lazyRetry } from '../lazyRetry.tsx';
import { commonPrivateRoutes, commonPublicRoutes } from './commonRoutes.tsx';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const Error500Boundary = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "Error500Boundary" */ '../Core/ErrorBoundary/Error500Boundary.tsx'),
    'Error500Boundary',
  ),
);

export const desktopRouter = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: process.env.NODE_ENV === 'development' ? null : <Error500Boundary />,
    children: [
      ...commonPublicRoutes,
      {
        errorElement: process.env.NODE_ENV === 'development' ? null : <Error500Boundary />,
        loader: authUserLoader,
        element: (
          <PrivateRoute>
            <AppLogout />
            <Navbar />
            <AppContainer />
          </PrivateRoute>
        ),
        children: [
          ...commonPrivateRoutes,
          {
            path: '/invoices',
            loader: invoicesLoader,
            async lazy() {
              const { Invoices } = await lazyRetry(
                () => import(/* webpackChunkName: "Invoices" */ '../Invoices/components/Invoices.tsx'),
                'Invoices',
              );
              return { Component: Invoices };
            },
          },
          {
            path: '/invoices/:id',
            loader: invoiceLoader,
            async lazy() {
              const { InvoicePageLoader } = await lazyRetry(
                () => import(/* webpackChunkName: "InvoicePageLoader" */ '../Invoices/components/InvoiceLoader.tsx'),
                'InvoicePageLoader',
              );
              return { Component: InvoicePageLoader };
            },
          },
          {
            path: '/invoices/:id/edit',
            loader: invoiceLoader,
            element: <InvoiceCreatorDataLoader />,
          },

          {
            path: '/estimates',
            loader: estimatesLoader,
            async lazy() {
              const { Estimates } = await lazyRetry(
                () => import(/* webpackChunkName: "Estimates" */ '../Estimates/components/Estimates.tsx'),
                'Estimates',
              );
              return { Component: Estimates };
            },
          },
          {
            path: '/estimates/:id',
            loader: estimateLoader,
            async lazy() {
              const { EstimatePageLoader } = await lazyRetry(
                () => import(/* webpackChunkName: "EstimatePageLoader" */ '../Estimates/components/EstimateLoader.tsx'),
                'EstimatePageLoader',
              );
              return { Component: EstimatePageLoader };
            },
          },
          {
            path: '/estimates/:id/edit',
            loader: estimateLoader,
            element: <EstimateCreatorDataLoader />,
          },
          {
            path: '/expenses',
            loader: expensesLoader,
            async lazy() {
              const { Expenses } = await lazyRetry(
                () => import(/* webpackChunkName: "Expenses" */ '../Expenses/components/Expenses.tsx'),
                'Expenses',
              );
              return { Component: Expenses };
            },
          },
          {
            path: '/expenses/:id',
            loader: expenseLoader,
            async lazy() {
              const { ExpensePageLoader } = await lazyRetry(
                () => import(/* webpackChunkName: "ExpensePageLoader" */ '../Expenses/components/ExpenseLoader.tsx'),
                'ExpensePageLoader',
              );
              return { Component: ExpensePageLoader };
            },
          },
          {
            path: '/expenses/:id/edit',
            loader: expenseLoader,
            async lazy() {
              const { ExpenseCreatorLoader } = await lazyRetry(
                () =>
                  import(
                    /* webpackChunkName: "ExpenseCreatorLoader" */ '../Expenses/components/ExpenseCreatorLoader.tsx'
                  ),
                'ExpenseCreatorLoader',
              );
              return { Component: ExpenseCreatorLoader };
            },
          },
          {
            path: '/cash-payments',
            loader: cashPaymentsLoader,
            async lazy() {
              const { CashPayments } = await lazyRetry(
                () => import(/* webpackChunkName: "CashPayments" */ '../CashPayments/components/CashPayments.tsx'),
                'CashPayments',
              );
              return { Component: CashPayments };
            },
          },
          {
            path: '/cash-payments/:id',
            loader: cashPaymentLoader,
            async lazy() {
              const { CashPaymentPageLoader } = await lazyRetry(
                () =>
                  import(
                    /* webpackChunkName: "CashPaymentPageLoader" */ '../CashPayments/components/CashPaymentLoader.tsx'
                  ),
                'CashPaymentPageLoader',
              );
              return { Component: CashPaymentPageLoader };
            },
          },
          {
            path: '/cash-payments/:id/edit',
            loader: cashPaymentLoader,
            async lazy() {
              const { CashPaymentCreatorLoader } = await lazyRetry(
                () =>
                  import(
                    /* webpackChunkName: "CashPaymentCreatorLoader" */ '../CashPayments/components/CashPaymentCreatorLoader.tsx'
                  ),
                'CashPaymentCreatorLoader',
              );
              return { Component: CashPaymentCreatorLoader };
            },
          },
          {
            path: '/clients',
            loader: clientsLoader,
            async lazy() {
              const { Clients } = await lazyRetry(
                () => import(/* webpackChunkName: "Clients" */ '../Clients/components/Clients.tsx'),
                'Clients',
              );
              return { Component: Clients };
            },
          },
          {
            path: '/clients/:id',
            loader: clientLoader,
            async lazy() {
              const { ClientPageLoader } = await lazyRetry(
                () => import(/* webpackChunkName: "ClientPageLoader" */ '../Clients/components/ClientLoader.tsx'),
                'ClientPageLoader',
              );
              return { Component: ClientPageLoader };
            },
          },
          {
            path: '/clients/:id/edit',
            loader: clientLoader,
            async lazy() {
              const { ClientCreatorLoader } = await lazyRetry(
                () =>
                  import(/* webpackChunkName: "ClientCreatorLoader" */ '../Clients/components/ClientCreatorLoader.tsx'),
                'ClientCreatorLoader',
              );
              return { Component: ClientCreatorLoader };
            },
          },
          {
            path: '/projects',
            loader: projectsLoader,
            async lazy() {
              const { Projects } = await lazyRetry(
                () => import(/* webpackChunkName: "Projects" */ '../Projects/components/Projects.tsx'),
                'Projects',
              );
              return { Component: Projects };
            },
          },
          {
            path: '/projects/:id',
            loader: projectLoader,
            async lazy() {
              const { ProjectPageLoader } = await lazyRetry(
                () => import(/* webpackChunkName: "ProjectPageLoader" */ '../Projects/components/ProjectLoader.tsx'),
                'ProjectPageLoader',
              );
              return { Component: ProjectPageLoader };
            },
          },
          {
            path: '/projects/:id/edit',
            loader: projectLoader,
            async lazy() {
              const { ProjectCreatorLoader } = await lazyRetry(
                () =>
                  import(
                    /* webpackChunkName: "ProjectCreatorLoader" */ '../Projects/components/ProjectCreatorLoader.tsx'
                  ),
                'ProjectCreatorLoader',
              );
              return { Component: ProjectCreatorLoader };
            },
          },
          {
            path: '/products',
            loader: productsLoader,
            async lazy() {
              const { Products } = await lazyRetry(
                () => import(/* webpackChunkName: "Products" */ '../Products/components/Products.tsx'),
                'Products',
              );
              return { Component: Products };
            },
          },
          {
            path: '/products/:id',
            loader: productLoader,
            async lazy() {
              const { ProductPageLoader } = await lazyRetry(
                () => import(/* webpackChunkName: "ProductPageLoader" */ '../Products/components/ProductLoader.tsx'),
                'ProductPageLoader',
              );
              return { Component: ProductPageLoader };
            },
          },

          {
            path: '/products/:id/edit',
            loader: productLoader,
            async lazy() {
              const { ProductCreatorLoader } = await lazyRetry(
                () =>
                  import(
                    /* webpackChunkName: "ProductCreatorLoader" */ '../Products/components/ProductCreatorLoader.tsx'
                  ),
                'ProductCreatorLoader',
              );
              return { Component: ProductCreatorLoader };
            },
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <RouteNotFound />,
  },
]);
