import * as Sentry from '@sentry/react';
import * as React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { authUserLoader } from '../Authentication/store/authenticatedUser.api.ts';
import { cashPaymentLoader, cashPaymentsLoader } from '../CashPayments/api/payments.api.ts';
import { clientLoader, clientsLoader } from '../Clients/api/clients.api.ts';
import { AppLogout } from '../Core/components/AutoLogout.tsx';
import { Navbar } from '../Core/components/Navbar/Navbar.tsx';
import { PrivateRoute } from '../Core/components/PrivateRoutes/PrivateRoute.tsx';
import { estimateLoader, estimatesLoader } from '../Estimates/api/estimates.api.ts';
import { expenseLoader, expensesLoader } from '../Expenses/api/expenses.api.ts';
import { invoiceLoader, invoicesLoader } from '../Invoices/api/invoices.api.ts';
import { productLoader, productsLoader } from '../Products/api/products.api.ts';
import { projectLoader, projectsLoader } from '../Projects/api/projects.api.ts';
import { AppContainer, Root, RouteNotFound } from '../Root.tsx';
import { EstimateCreatorDataLoader, InvoiceCreatorDataLoader } from '../Treasuries/components/TreasuryDataLoader.tsx';
import { lazyRetry } from '../lazyRetry.tsx';
import { commonPrivateRoutes, commonPublicRoutes } from './commonRoutes.tsx';

const Error500Boundary = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "Error500Boundary" */ '../Core/ErrorBoundary/Error500Boundary.tsx'),
    'Error500Boundary',
  ),
);
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const mobileRouter = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: process.env.NODE_ENV === 'development' ? null : <Error500Boundary />,
    children: [
      ...commonPublicRoutes,
      {
        errorElement: process.env.NODE_ENV === 'development' ? null : <Error500Boundary />,
        loader: authUserLoader,
        element: (
          <PrivateRoute>
            <AppLogout />
            <Navbar />
            <AppContainer />
          </PrivateRoute>
        ),
        children: [
          ...commonPrivateRoutes,
          {
            path: '/invoices/*',
            loader: invoicesLoader,
            async lazy() {
              const { Invoices } = await lazyRetry(
                () => import(/* webpackChunkName: "InvoicesMobile" */ '../Invoices/components/InvoicesMobile.tsx'),
                'InvoicesMobile',
              );
              return { Component: Invoices };
            },
          },
          {
            path: '/invoices/:id',
            loader: invoiceLoader,
            async lazy() {
              const { InvoicePageLoader } = await lazyRetry(
                () => import(/* webpackChunkName: "InvoicePageLoader" */ '../Invoices/components/InvoiceLoader.tsx'),
                'InvoicePageLoader',
              );
              return { Component: InvoicePageLoader };
            },
          },
          {
            path: '/invoices/:id/edit',
            loader: invoiceLoader,
            element: <InvoiceCreatorDataLoader />,
          },
          {
            path: '/estimates/*',
            loader: estimatesLoader,
            async lazy() {
              const { Estimates } = await lazyRetry(
                () => import(/* webpackChunkName: "EstimatesMobile" */ '../Estimates/components/EstimatesMobile.tsx'),
                'Estimates',
              );
              return { Component: Estimates };
            },
            children: [
              {
                index: true,
                async lazy() {
                  const { EstimatesHome } = await lazyRetry(
                    () =>
                      import(/* webpackChunkName: "EstimatesMobileHome" */ '../Estimates/components/EstimatesHome.tsx'),
                    'EstimatesHome',
                  );
                  return { Component: EstimatesHome };
                },
              },
              {
                path: ':id',
                loader: estimateLoader,
                children: [
                  {
                    index: true,
                    loader: estimateLoader,
                    async lazy() {
                      const { EstimatePageLoader } = await lazyRetry(
                        () =>
                          import(
                            /* webpackChunkName: "EstimateMobilePageLoader" */ '../Estimates/components/EstimateLoader.tsx'
                          ),
                        'EstimatePageLoader',
                      );
                      return { Component: EstimatePageLoader };
                    },
                  },
                  {
                    path: 'edit',
                    loader: estimateLoader,
                    element: <EstimateCreatorDataLoader />,
                  },
                ],
              },
            ],
          },

          {
            path: '/expenses/*',
            async lazy() {
              const { Expenses } = await lazyRetry(
                () => import(/* webpackChunkName: "ExpensesMobile" */ '../Expenses/components/ExpensesMobile.tsx'),
                'ExpensesMobile',
              );
              return { Component: Expenses };
            },
            loader: expensesLoader,
            children: [
              {
                index: true,
                async lazy() {
                  const { ExpensesHome } = await lazyRetry(
                    () => import(/* webpackChunkName: "ExpensesHome" */ '../Expenses/components/ExpensesHome.tsx'),
                    'ExpensesHome',
                  );
                  return { Component: ExpensesHome };
                },
              },
              {
                path: ':id',
                loader: expenseLoader,
                children: [
                  {
                    index: true,
                    loader: expenseLoader,
                    async lazy() {
                      const { ExpensePageLoader } = await lazyRetry(
                        () =>
                          import(
                            /* webpackChunkName: "ExpensePageLoader" */ '../Expenses/components/ExpenseLoader.tsx'
                          ),
                        'ExpensePageLoader',
                      );
                      return { Component: ExpensePageLoader };
                    },
                  },
                  {
                    path: 'edit',
                    loader: expenseLoader,

                    async lazy() {
                      const { ExpenseCreatorLoader } = await lazyRetry(
                        () =>
                          import(
                            /* webpackChunkName: "ExpenseCreatorLoader" */ '../Expenses/components/ExpenseCreatorLoader.tsx'
                          ),
                        'ExpenseCreatorLoader',
                      );
                      return { Component: ExpenseCreatorLoader };
                    },
                  },
                ],
              },
            ],
          },

          {
            path: '/cash-payments/*',
            loader: cashPaymentsLoader,
            async lazy() {
              const { CashPayments } = await lazyRetry(
                () =>
                  import(
                    /* webpackChunkName: "CashPaymentsMobile" */ '../CashPayments/components/CashPaymentsMobile.tsx'
                  ),
                'CashPaymentsMobile',
              );
              return { Component: CashPayments };
            },

            children: [
              {
                index: true,
                async lazy() {
                  const { CashPaymentsHome } = await lazyRetry(
                    () =>
                      import(
                        /* webpackChunkName: "CashPaymentsHome" */ '../CashPayments/components/CashPaymentsHome.tsx'
                      ),
                    'CashPaymentsHome',
                  );
                  return { Component: CashPaymentsHome };
                },
              },
              {
                path: ':id',
                loader: cashPaymentLoader,
                children: [
                  {
                    index: true,
                    loader: cashPaymentLoader,
                    async lazy() {
                      const { CashPaymentPageLoader } = await lazyRetry(
                        () =>
                          import(
                            /* webpackChunkName: "CashPaymentPageLoader" */ '../CashPayments/components/CashPaymentLoader.tsx'
                          ),
                        'CashPaymentPageLoader',
                      );
                      return { Component: CashPaymentPageLoader };
                    },
                  },
                  {
                    path: 'edit',
                    loader: cashPaymentLoader,
                    async lazy() {
                      const { CashPaymentCreatorLoader } = await lazyRetry(
                        () =>
                          import(
                            /* webpackChunkName: "CashPaymentCreatorLoader" */ '../CashPayments/components/CashPaymentCreatorLoader.tsx'
                          ),
                        'CashPaymentCreatorLoader',
                      );
                      return { Component: CashPaymentCreatorLoader };
                    },
                  },
                ],
              },
            ],
          },
          {
            path: '/clients/*',
            async lazy() {
              const { Clients } = await lazyRetry(
                () => import(/* webpackChunkName: "ClientsMobile" */ '../Clients/components/ClientsMobile.tsx'),
                'ClientsMobile',
              );
              return { Component: Clients };
            },
            loader: clientsLoader,
            children: [
              {
                index: true,
                async lazy() {
                  const { ClientsHome } = await lazyRetry(
                    () => import(/* webpackChunkName: "ClientsHome" */ '../Clients/components/ClientsHome.tsx'),
                    'ClientsHome',
                  );
                  return { Component: ClientsHome };
                },
              },
              {
                path: ':id',
                loader: clientLoader,
                children: [
                  {
                    index: true,
                    loader: clientLoader,
                    async lazy() {
                      const { ClientPageLoader } = await lazyRetry(
                        () =>
                          import(/* webpackChunkName: "ClientPageLoader" */ '../Clients/components/ClientLoader.tsx'),
                        'ClientPageLoader',
                      );
                      return { Component: ClientPageLoader };
                    },
                  },
                  {
                    path: 'edit',
                    loader: clientLoader,
                    async lazy() {
                      const { ClientCreatorLoader } = await lazyRetry(
                        () =>
                          import(
                            /* webpackChunkName: "ClientCreatorLoader" */ '../Clients/components/ClientCreatorLoader.tsx'
                          ),
                        'ClientCreatorLoader',
                      );
                      return { Component: ClientCreatorLoader };
                    },
                  },
                ],
              },
            ],
          },
          {
            path: '/projects/*',
            async lazy() {
              const { Projects } = await lazyRetry(
                () => import(/* webpackChunkName: "ProjectsMobile" */ '../Projects/components/ProjectsMobile.tsx'),
                'ProjectsMobile',
              );
              return { Component: Projects };
            },
            loader: projectsLoader,
            children: [
              {
                index: true,
                async lazy() {
                  const { ProjectsHome } = await lazyRetry(
                    () => import(/* webpackChunkName: "ProjectsHome" */ '../Projects/components/ProjectsHome.tsx'),
                    'ProjectsHome',
                  );
                  return { Component: ProjectsHome };
                },
              },
              {
                path: ':id',
                loader: projectLoader,
                children: [
                  {
                    index: true,
                    loader: projectLoader,
                    async lazy() {
                      const { ProjectPageLoader } = await lazyRetry(
                        () =>
                          import(
                            /* webpackChunkName: "ProjectPageLoader" */ '../Projects/components/ProjectLoader.tsx'
                          ),
                        'ProjectPageLoader',
                      );
                      return { Component: ProjectPageLoader };
                    },
                  },
                  {
                    path: 'edit',
                    loader: projectLoader,

                    async lazy() {
                      const { ProjectCreatorLoader } = await lazyRetry(
                        () =>
                          import(
                            /* webpackChunkName: "ProjectCreatorLoader" */ '../Projects/components/ProjectCreatorLoader.tsx'
                          ),
                        'ProjectCreatorLoader',
                      );
                      return { Component: ProjectCreatorLoader };
                    },
                  },
                ],
              },
            ],
          },
          {
            path: '/products/*',
            loader: productsLoader,
            async lazy() {
              const { Products } = await lazyRetry(
                () => import(/* webpackChunkName: "ProductsMobile" */ '../Products/components/ProductsMobile.tsx'),
                'ProductsMobile',
              );
              return { Component: Products };
            },
            children: [
              {
                index: true,
                async lazy() {
                  const { ProductsHome } = await lazyRetry(
                    () => import(/* webpackChunkName: "ProductsHome" */ '../Products/components/ProductsHome.tsx'),
                    'ProductsHome',
                  );
                  return { Component: ProductsHome };
                },
              },
              {
                path: ':id',
                loader: productLoader,
                children: [
                  {
                    index: true,
                    loader: productLoader,
                    async lazy() {
                      const { ProductPageLoader } = await lazyRetry(
                        () =>
                          import(
                            /* webpackChunkName: "ProductPageLoader" */ '../Products/components/ProductLoader.tsx'
                          ),
                        'ProductPageLoader',
                      );
                      return { Component: ProductPageLoader };
                    },
                  },
                  {
                    path: 'edit',
                    loader: productLoader,
                    async lazy() {
                      const { ProductCreatorLoader } = await lazyRetry(
                        () =>
                          import(
                            /* webpackChunkName: "ProductCreatorLoader" */ '../Products/components/ProductCreatorLoader.tsx'
                          ),
                        'ProductCreatorLoader',
                      );
                      return { Component: ProductCreatorLoader };
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <RouteNotFound />,
  },
]);
