import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import * as React from 'react';
import { toFormattedDate } from '../../Core/utils/dateTime.ts';
import { Project } from '../../Projects/api/projects.types';

interface Props {
  project: Project;
  query?: string;
}

export const SpotlightProjectDetail: React.FC<Props> = ({ project }) => {
  return project ? (
    <div className="d-flex flex-column justify-content-center">
      <Flex direction="column" justifyContent="space-between" mb={4}>
        <Flex justifyContent="space-between" direction="row" color="gray.500">
          <Text>{project.startedAt ? toFormattedDate(project.startedAt) : null}</Text>
          <Text>{project.finishedAt ? toFormattedDate(project.finishedAt) : null}</Text>
        </Flex>
        <Flex alignItems="center" mt={4}>
          <Heading as="h5" maxW="xl" size="md" color="gray.600">
            {project.name} <span> &#9472; {project.code}</span>
          </Heading>
        </Flex>
      </Flex>

      <Text pb={4} whiteSpace="pre-line">
        {project.description}
      </Text>

      <Box my={6}>
        <Text color="gray.500" fontSize="md">
          Белешки
        </Text>
        <Text whiteSpace="pre-line">{project.notes}</Text>
      </Box>
    </div>
  ) : null;
};
