import * as React from 'react';
import { useAppSelector } from '../AppStore';
import { useGetCurrentUser } from '../utils/getCurrentUserFromLocalStorage.ts';

export const useIsAuthenticated = () => {
  const authenticated = useAppSelector(({ authenticated }) => authenticated.isAuthenticated);
  const [user] = useGetCurrentUser();
  const isAuthenticatedUser = user != null;

  const isAuthenticated: boolean = React.useMemo(
    () => authenticated || isAuthenticatedUser,
    [authenticated, isAuthenticatedUser],
  );

  return [isAuthenticated] as const;
};
