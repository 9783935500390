import { Badge, Flex, Heading, Text } from '@chakra-ui/react';
import * as React from 'react';
import { getTranslatedProductType } from '../../Core/utils/translatableTypes.ts';
import { Product } from '../../Products/api/products.types';
import { formatMoney } from '../../Treasuries/store/treasuries.utils';
import { useAppSelector } from '../../Core/AppStore.ts';

interface Props {
  product: Product;
  query?: string;
}

export const SpotlightProductDetail: React.FC<Props> = ({ product }) => {
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  return product ? (
    <div className="d-flex flex-column justify-content-center">
      <Flex justifyContent="space-between" mb={2}>
        <Text color="gray.400">{product?.skuCode}</Text>
        <Badge colorScheme={colorScheme} variant="subtle" textTransform="none">
          {getTranslatedProductType(product.type)}
        </Badge>
      </Flex>
      <Flex direction="column" width="100%">
        <Heading as="h1" width="75%" fontSize="xl" textColor="gray.600">
          {product?.name}
        </Heading>
        <Text color="gray.500">{product?.description}</Text>
      </Flex>
      <Flex direction="column" mt={4}>
        <Text>Цена на единица</Text>
        <Text fontSize="2xl" fontWeight="bold" ml={0} pl={0}>
          {product?.unitPrice ? formatMoney(product?.unitPrice, product?.currency).trim() : null}
          <Text as="span"> ({product?.unit}) </Text>
        </Text>
      </Flex>

      <Text mt={6} mb={2}>
        Белешки
      </Text>
      <Flex>
        <Text whiteSpace="pre-line">{product?.notes}</Text>
      </Flex>
    </div>
  ) : null;
};
SpotlightProductDetail.displayName = 'SpotlightProductDetail';
