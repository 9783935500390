import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import packageJson from '../package.json';
import { getCurrentUserFromLocalStorage } from './Core/utils/getCurrentUserFromLocalStorage.ts';

const SENTRY_DSN = import.meta.env.VITE_APP_SENTRY_DSN;

const sentryFeedback = Sentry.feedbackIntegration({
  autoInject: false,
  showBranding: false,
  formTitle: 'Остави фидбек!',
  buttonLabel: 'Фидбек',
  colorScheme: 'light',
  showName: false,
  showEmail: false,
  messagePlaceholder: '',
  messageLabel: 'Фидбек или проблем?',
  submitButtonLabel: 'Прати',
  cancelButtonLabel: 'Откажи',
  successMessageText: 'Ви благодариме за вашиот коментар',
  useSentryUser: {
    email: 'email',
    name: 'username',
  },
});

export const setupSentry = () => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }
  Sentry.init({
    release: `brojki@${packageJson.version}`,
    dsn: SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      sentryFeedback,
    ],
    tracesSampleRate: 1.0,
    ignoreErrors: ['Non-Error exception captured', 'Unauthenticated', 'CSRF token mismatch'],
    environment: process.env.NODE_ENV,
  });
};

export const useSentryUser = (isAuthenticated: boolean = false) => {
  if (!isAuthenticated) {
    return;
  }

  const [user] = getCurrentUserFromLocalStorage();

  if (!user) {
    return;
  }

  if (process.env.NODE_ENV === 'production') {
    Sentry.setUser({ id: user.id, fullName: user.name, username: user.name, email: user.email });
  }
};
