import { RouteObject } from 'react-router-dom';
import * as React from 'react';
import LoginPage from '../Authentication/components/AuthenticationPage/LoginPage.tsx';
import SignupPage from '../Authentication/components/AuthenticationPage/SignupPage.tsx';
import TermsConditions from '../Authentication/components/AuthenticationPage/TermsConditions.tsx';
import { PublicRoute } from '../Core/components/PrivateRoutes/PublicRoute.tsx';
import { dashboardCountersLoader } from '../Dashboard/api/dashboard.api.ts';
import { lazyRetry } from '../lazyRetry.tsx';

const Company = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "CompanyPage" */ '../Company/components/CompanyPage.tsx'), 'CompanyPage'),
);
const Profile = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Profile" */ '../Core/components/Profile/Profile.tsx'), 'Profile'),
);

export const commonPublicRoutes: RouteObject[] = [
  {
    index: true,
    element: (
      <PublicRoute>
        <LoginPage />
      </PublicRoute>
    ),
  },
  {
    path: '/login',
    element: (
      <PublicRoute>
        <LoginPage />
      </PublicRoute>
    ),
  },
  {
    path: '/signup',
    element: (
      <PublicRoute>
        <SignupPage />
      </PublicRoute>
    ),
  },
  {
    path: '/terms-and-conditions',
    element: (
      <PublicRoute>
        <TermsConditions />
      </PublicRoute>
    ),
  },
];

export const commonPrivateRoutes: RouteObject[] = [
  {
    path: '/dashboard',
    loader: dashboardCountersLoader,
    async lazy() {
      const { Dashboard } = await lazyRetry(
        () => import(/* webpackChunkName: "Dashboard" */ '../Dashboard/components/Dashboard'),
        'Dashboard',
      );
      return { Component: Dashboard };
    },
  },
  {
    path: '/notifications',
    async lazy() {
      const { Notifications } = await lazyRetry(
        () => import(/* webpackChunkName: "Notifications" */ '../Notifications/components/Notifications.tsx'),
        'Notifications',
      );
      return { Component: Notifications };
    },
  },
  {
    path: '/notifications/:id',
    async lazy() {
      const { NotificationPage } = await lazyRetry(
        () => import(/* webpackChunkName: "NotificationPage" */ '../Notifications/components/NotificationPage.tsx'),
        'NotificationPage',
      );
      return { Component: NotificationPage };
    },
  },
  {
    path: '/profile',
    element: <Profile />,
  },
  {
    path: '/company',
    element: <Company />,
    children: [
      {
        index: true,
        async lazy() {
          const { CompanyProfile } = await lazyRetry(
            () => import(/* webpackChunkName: "CompanyProfile" */ '../Company/components/CompanyProfile.tsx'),
            'CompanyProfile',
          );
          return { Component: CompanyProfile };
        },
      },
      {
        path: 'profile',
        async lazy() {
          const { CompanyProfile } = await lazyRetry(
            () => import(/* webpackChunkName: "CompanyProfile" */ '../Company/components/CompanyProfile.tsx'),
            'CompanyProfile',
          );
          return { Component: CompanyProfile };
        },
      },
      {
        path: 'settings',
        async lazy() {
          const { CompanySettings } = await lazyRetry(
            () => import(/* webpackChunkName: "CompanySettings" */ '../Company/components/CompanySettings.tsx'),
            'CompanySettings',
          );
          return { Component: CompanySettings };
        },
      },
      {
        path: 'trash',
        async lazy() {
          const { CompanyTrash } = await lazyRetry(
            () => import(/* webpackChunkName: "CompanyTrash" */ '../Company/components/CompanyTrash.tsx'),
            'CompanyTrash',
          );
          return { Component: CompanyTrash };
        },
      },
      {
        path: 'audits',
        async lazy() {
          const { CompanyAudits } = await lazyRetry(
            () => import(/* webpackChunkName: "CompanyAudits" */ '../Company/components/CompanyAudits.tsx'),
            'CompanyAudits',
          );
          return { Component: CompanyAudits };
        },
      },
    ],
  },
];
